<template>

  <div :class="divClass" v-html="html"></div>

</template>

<script>

let md = require('markdown-it')();

export default {

  name: 'Markdown',

  props: {

    markdown: {
        type: String,
        default: ''
    },

    baseClass: {
        type: String,
        default: 'telkom-markdown whitespace-pre-wrap'
    },

    classes: {
        type: String,
        default: ''
    }
  },

  computed: {

    html () {
        return md.render(this.markdown);
    },

    divClass () {
      return `${this.baseClass} ${this.classes}`;
    }
  }
}
</script>
